import React, { memo, ReactNode, useMemo } from 'react';
import ThemeProvider from '@plasma/ui.themes.theme-provider';
import { ThemeOverride } from '@plasma/ui.utils.types';
import useOverviewHook from './api-overview.hook';
import * as styled from './api-overview.styles';
import themes from './themes';
import TextInput from '@plasma/ui.input.text-input';
import Select from '@plasma/ui.input.select/dist/select';
import Button from '@plasma/ui.general.button';
import DataGrid, { ColumnType } from '@plasma/ui.display.data-grid';
import Modal from '@plasma/ui.layout.modal';
import { ApiMeta } from '../../../models/types/apiMeta';
import StatusIndicator from '../../../components/core/StatusIndicator/StatusIndicator';
import { Icon } from '@plasma/ui.general.icon';
import ToolBar from '../../../components/features/api-overview/ToolBar/tool-bar';
import CustomDataGrid, { ColumnType2 } from '../../../components/core/CustomDataGrid/CustomDataGrid';
import ServiceMenu from '../../../components/features/service-overview/ServiceMenu/ServiceMenu';
import { Button as AntButton, Menu } from 'antd';
import ServiceHistory from '../../../components/features/service-overview/ServiceHistory/ServiceHistory';
import {
  DeleteOutlined,
  EditOutlined,
  FileTextOutlined,
  HistoryOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';
import Secure from '@plasma/ui.application.secure';

export interface OverviewProps {
  children?: ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

type Props = OverviewProps & ThemeOverride;

function Overview(props: Props) {
  const { children, className, style, size, themeMode } = props;
  //@ts-ignore
  const adminRole = window.env.ADMIN_ROLE;
  const logic = useOverviewHook();

  const handleEndpointStatusModal = (id: string, status: boolean) => {
    Modal.confirm({
      title: status ? logic.t('status.activate.title') : logic.t('status.deactivate.title'),
      icon: <Icon style={{ color: status ? 'green' : 'red' }} name="GppMaybeOutlined" />,
      content: status ? logic.t('status.activate.confirm.message') : logic.t('status.deactivate.confirm.message'),
      sizeTheme: size,
      onOk() {
        logic.onChangeStauts(id, status);
      },
      centered: true,
      okType: 'danger',
      visible: true,
    });
  };

  const handleNamespaceDeleteModal = (namespace: string) => {
    Modal.confirm({
      title: logic.t('namespace.delete.title'),
      icon: <Icon style={{ color: 'red' }} name="GppMaybeOutlined" />,
      content: logic.t('namespace.delete.confirm.message'),
      sizeTheme: size,
      onOk() {
        logic.onDeleteNamespace(namespace);
      },
      centered: true,
      okType: 'danger',
      visible: true,
    });
  };

  const columns2: ColumnType2<ApiMeta>[] = [
    {
      width: 200,
      dataIndex: 'status',
      title: logic.t('header.status'),
      key: 'status',
      render: (value, row) => {
        if (row.depth === 1) return <StatusIndicator active={row.status} size={16} />;
        return null;
      },
    },
    {
      width: 250,
      dataIndex: 'namespace',
      title: logic.t('header.namespace'),
      key: 'namespace',
    },
    {
      width: 250,
      dataIndex: 'name',
      title: logic.t('header.name'),
      key: 'name',
    },
    {
      width: 100,
      minWidth: 100,
      title: logic.t('header.method'),
      dataIndex: 'method',
      key: 'protocol',
    },
    {
      dataIndex: 'path',
      title: logic.t('header.path'),
      key: 'ds',
    },
    {
      width: 32,
      title: "",
      dataIndex: 'id',
      key: 'id',
      render: (value, row) => {
        if (row.depth === 0)
          return (
            <div className="action-container">
              <AntButton type="link" icon={<FileTextOutlined />} onClick={() => logic.onSwagger(row.groupByValue)}>
                {logic.t('button.swagger')}
              </AntButton>
              <Secure permissions={[adminRole]}>
              <ServiceMenu  icon={<Icon name="more_vert"  />}>
              <Menu.Item className='none-active-item'  key={"1"} title={logic.t('button.delete')}  onClick={() =>handleNamespaceDeleteModal(row.groupByValue)} icon={<DeleteOutlined/>}>
                {logic.t('button.delete')}
                </Menu.Item>
                {/* <Menu.Item className='none-active-item'  key={"2"} title={logic.t('button.edit')}  onClick={() =>handleNamespaceDeleteModal(row.groupByValue)} icon={<EditOutlined/>}>
                {logic.t('button.edit')}
                </Menu.Item> */}
              </ServiceMenu>
              </Secure>
            </div>
          );
        return (
          <div className="action-container">
            <Secure permissions={[adminRole]}>
              {row.status ? (
                <AntButton
                  icon={<PauseCircleOutlined />}
                  onClick={() => {
                    handleEndpointStatusModal(value, false);
                  }}
                  type="link"
                >
                  {logic.t('button.deactivate')}
                </AntButton>
              ) : (
                <AntButton
                  icon={<PlayCircleOutlined />}
                  onClick={() => {
                    handleEndpointStatusModal(value, true);
                  }}
                  type="link"
                >
                  {logic.t('button.activate')}
                </AntButton>
              )}
              <AntButton
                icon={<EditOutlined />}
                onClick={() => {
                  logic.handleNavigate(`edit/${value}`,true);
                }}
                type="link"
                title={logic.t('button.edit')}
              >
                {logic.t('button.edit')}
              </AntButton>
            </Secure>
          </div>
        );
      },
    },
  ];

  return (
    <ThemeProvider themes={themes} selectedSize={size} selectedMode={themeMode}>
      <styled.Overview className={`overview ${className ?? ''}`.trim()} style={style}>
        <div className="mainbar">
          <TextInput
            onChange={(value: React.ChangeEvent<HTMLInputElement>) => logic.filterChange(value)}
            placeholder={logic.t('searchField.placeholder')}
            className="searchInput"
          />
          <ToolBar
            className="toolBar"
            onSpanChange={logic.rangeChange}
            handleNavigate={logic.handleNavigate}
            rangeValues={logic.rangeValues}
          />
        </div>
        <div className="tableContainer">
          <CustomDataGrid<ApiMeta>
            data={logic.filteredData ?? []}
            columns={columns2}
            groupNames={['namespace']}
            loading={logic.loading ?? false}
          ></CustomDataGrid>
        </div>
      </styled.Overview>
    </ThemeProvider>
  );
}

export default Overview;
