import React, { ReactNode } from 'react';
import ThemeProvider from '@plasma/ui.themes.theme-provider';
import { ThemeOverride } from '@plasma/ui.utils.types';
import useOverviewHook from './service-overview.hook';
import * as styled from './service-overview.styles';
import themes from './themes';
import TextInput from '@plasma/ui.input.text-input';
import { DownstreamService } from '../../../models/types/downstreamEndpoint';
import Icon from '@plasma/ui.general.icon';
import StatusIndicator from '../../../components/core/StatusIndicator/StatusIndicator';
import CustomDataGrid, { ColumnType2 } from '../../../components/core/CustomDataGrid/CustomDataGrid';
import { Button as AntButton, Menu, Modal } from 'antd';
import { AreaChartOutlined, EditOutlined, FileAddOutlined, FileTextOutlined, HistoryOutlined, LoadingOutlined} from '@ant-design/icons';
import ServiceMenu from '../../../components/features/service-overview/ServiceMenu/ServiceMenu';
import ServiceHistory from '../../../components/features/service-overview/ServiceHistory/ServiceHistory';
import Secure from "@plasma/ui.application.secure"
import ServiceStatusModal from '../../../components/features/service-overview/ServiceStatusModal/ServiceStatusModal';
import { ServiceStatus } from '../../../models/enums/ServiceStatus';
import Button from "@plasma/ui.general.button";
export interface OverviewProps {
  children?: ReactNode;
  /*
   * Defines custom className
   */
  className?: string;
  /*
   * Defines component's custom style
   */
  style?: React.CSSProperties;
  // add new properties here...
}

type Props = OverviewProps & ThemeOverride;

function Overview(props: Props) {
  const { children, className, style, size, themeMode } = props;

  const logic = useOverviewHook();

//@ts-ignore
const adminRole = window.env.ADMIN_ROLE;

  const columns2: ColumnType2<DownstreamService & { inUse: boolean; serverStatus:ServiceStatus }>[] = [
    {
      dataIndex: 'groupName',
      title: logic.t('header.group-name'),
      key: 'group-name',
      minWidth: 200,
    },
    {
      dataIndex: 'serviceName',
      title: logic.t('header.service-name'),
      key: 'name',
      minWidth: 200,
    },
    {
      dataIndex: 'version',
      title: logic.t('header.version'),
      key: 'version',
      render: (value, row) => {
        if (row.depth === 1)
          return (
            <AntButton
              danger={!row.subElements[0].version}
              style={{ padding: 0 }}
              type="link"
              onClick={() => {
                if (row.subElements[0].version) logic.navigateServiceSwagger(row.subElements[0].serviceId);
              }}
              icon={<FileTextOutlined />}
            >
              {row.subElements[0].version}
            </AntButton>
          );
        return null;
      },
    },
    {
      dataIndex: 'serverStatus',
      title: logic.t('header.serverAvailable'),
      key: 'ds',

      render: (value, row) => {

        if (row.depth === 1){
          return (
            <div style={{cursor:"pointer",width:"fit-content"}} onClick={()=>logic.setShowStatus([row.subElements[0].serviceId,row.subElements[0].serviceName])}>
              <StatusIndicator  status={logic.statusDictionary?.[row.subElements[0].serviceId]} size={16} falseVaulue="" trueVaulue="" />
            </div>
          )}
        return null;
      },
    },
    {
      width: 100,
      minWidth: 100,
      title: logic.t('header.method'),
      dataIndex: 'method',
      key: 'protocol',
    },
    {
      minWidth: 200,
      title: logic.t('header.name'),
      dataIndex: 'name',
      key: 'protocol',
    },
    {
      minWidth: 250,
      title: logic.t('header.summary'),
      dataIndex: 'summary',
      key: 'protocol',
    },
    {
      minWidth: 150,
      title: logic.t('header.tag'),
      dataIndex: 'tag',
      key: 'tag',
    },
    {
      minWidth: 100,
      width: 100,
      title: logic.t('header.inUse'),
      dataIndex: 'inUse',
      key: 'inUse',
      render: (value: boolean, row, index) => {
        if (row.depth < 2) {
          let total = row.subElements.length;
          let used = row.subElements.filter((e) => e.inUse === true).length;
          return (
            <span>
              {used}/{total}
            </span>
          );
        } else
          return (
            <>
              {value ? (
                <Icon style={{ color: 'lightgreen' }} name="check_circle_outline" />
              ) : (
                <Icon name="radio_button_unchecked" />
              )}
            </>
          );
      },
    },
    {
      width: 32,
      title: '',
      dataIndex: 'id',
      key: 'id',
      render: (_value, row) => {
        if (row.depth === 1)
          return (
            <ServiceMenu open={logic.serviceMenuOpen == row.subElements[0].serviceId} setOpen={logic.setServiceMenuOpen} icon={<Icon name="more_vert" onClick={()=>{logic.setServiceMenuOpen(row.subElements[0].serviceId)}} />}>
              <Secure permissions={[adminRole]}>
                <Menu.Item className='none-active-item'  key={"1"} title={logic.t('header.edit')}  onClick={() => logic.handleNavigate(`edit/${row.subElements[0].serviceId}`,true)} icon={<EditOutlined/>}>
                {logic.t('header.edit')}
                </Menu.Item>
              </Secure>
              <Menu.Item  key={"2"} title={logic.t('header.monitor')} onClick={()=>{logic.setServiceMenuOpen(undefined);logic.setShowStatus([row.subElements[0].serviceId,row.subElements[0].serviceName])}} icon={<AreaChartOutlined />}>
                {logic.t('header.monitor')}
                </Menu.Item>
              <ServiceMenu
              icon={  <Menu.Item key={"3"} className='none-active-item'  title={logic.t('header.documents')}  icon={<FileTextOutlined />}>
              {logic.t('button.documents')}
              </Menu.Item>}
              >
                <ServiceMenu
                  icon={<Menu.Item className='none-active-item' title={logic.t('button.document-history')}  icon={<HistoryOutlined />} >{logic.t('button.document-history')} </Menu.Item>}
                >
                  <ServiceHistory servicdeId={row.subElements[0].serviceId} />
                </ServiceMenu>
                <Secure permissions={[adminRole]}>
                  <Menu.Item
                  className='none-active-item'
                    onClick={() => {
                      if (logic.fileInputRef.current) {
                        logic.fileInputRef.current.click();
                      }
                    }}
                    title={logic.t('button.document-upload')}
                    icon={logic.isUploading?<LoadingOutlined />:<FileAddOutlined />}
                  >
                    {logic.t('button.document-upload')}
                  </Menu.Item>
                  <input
                    ref={logic.fileInputRef}
                    type="file"
                    style={{ display: 'none' }}
                    multiple
                    onChange={(e) => {
                      logic.handleUpload(row.subElements[0].serviceId, e.target.files);
                    }}
                  />
                </Secure>
              </ServiceMenu>
            </ServiceMenu>
          );
        return null;
      },
    },
  ];

  return (
    <ThemeProvider themes={themes} selectedSize={size} selectedMode={themeMode}>
      <styled.Overview className={`overview ${className ?? ''}`.trim()} style={style}>
        <div className="toolbar">
          <TextInput
            onChange={logic.onFilterChange}
            placeholder={logic.t('searchField.placeholder')}
            className="searchInput"
          />
          <div className="buttonContainer">
          <Secure permissions={[adminRole]}>
            <Button
              onClick={() => {
                logic.handleNavigate(`create`,true);
              }}
              title={logic.t('button.importServices')}
            />
            </Secure>
          </div>
        </div>
        <div className="tableContainer">
          <CustomDataGrid<DownstreamService & { inUse: boolean; serverStatus: ServiceStatus }>
            data={logic.filteredData ?? []}
            defaultExpandGroups={['groupName']}
            columns={columns2}
            groupNames={['groupName', 'serviceName']}
            loading={logic.isLoading ?? false}
          ></CustomDataGrid>
        </div>
        <Modal width={"80%"} cancelButtonProps={{ style: { display: 'none' } }}  title={ `Health Monitor - ${logic.showStatus?.[1]}`} destroyOnClose visible={!!logic.showStatus} onCancel={()=>logic.setShowStatus(undefined)} onOk={()=>logic.setShowStatus(undefined)}>
          <ServiceStatusModal id={logic.showStatus?.[0]}/>
        </Modal>
      </styled.Overview>
    </ThemeProvider>
  );
}

export default Overview;
