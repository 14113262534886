import React, { Component, useMemo, useState } from 'react';
import { useHelpBundle } from '@plasma/ui.application.app';
import { useResourceBundle, useTranslation } from '@plasma/ui.utils.i18n';
import translations from './i18n';
import help from './api-overview.help';
import { debounce } from 'lodash';
import { useNavigate } from '@plasma/ui.application.router';
import { dataHooks } from '../../../hooks/dataHooks';
import { ApiMeta } from '../../../models/types/apiMeta';
import { agent } from '../../../api/agent';
import { LocalStorageSettingsStore } from '@plasma/ui.utils.settings-store';

export type DataType = {
  description: string;
  method: string;
  path: string;
  protocol: string;
  requestBody: string;
  status: boolean;
  namespace: string;
  name: string;
  requests: number;
  errorRate: number;
};

function useOverviewHook() {
  useResourceBundle(translations, undefined, '40acb31e-71f7-45bd-b0c2-a47ccf7d5f0c');
  useHelpBundle(help);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [namespaceToDelete, setNamespaceToDelete] = useState<string | null>();
  const { data, loading, setData, refetch } = dataHooks.useGetInitialData<ApiMeta[]>(
    () => agent.upstreamEndpoint.getAllMeta(),
    false,
    true,
  );

  const { execute: changeStatus, loading: isToggling } = dataHooks.useApiCall(
    null,
    t('notification.endpoints.statusChange.success'),
    null,
  );
  const { execute: deleteNamespace, loading: isDeleting } = dataHooks.useApiCall(
    null,
    t('notification.namespace.delete.success'),
    null,
  );

  const [filter, setFilter] = useState('');

  const filteredData = useMemo(() => {
    if (data) {
      let newArray = [...data];
      return newArray.filter((element) => Object.values(element).some((value) => value?.toString().toLowerCase().includes(filter.toLowerCase())));
    }
    return [];
  }, [data, filter]);

  const rangeValues = [
    { value: 24, label: t('rangePicker.last-24') },
    { value: 48, label: t('rangePicker.last-48') },
  ];

  const debouncedSetFilteredData = debounce((filter) => {
    setFilter(filter);
  }, 300);

  const filterChange = (filter: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSetFilteredData(filter.target.value);
  };

  const rangeChange = (filter: any) => {};

  const handleNavigate = (path: string, newTab?: boolean) => {
    if (newTab) window.open(`apis/${path}`, '_blank');
    else navigate(path);
  };

  const onChangeStauts = (id: string, value: boolean) => {
    changeStatus(() =>
      agent.upstreamEndpoint.changeStatus(id, value).then(() => {
        const newData = [...data!];
        let endpoint = newData.find((endpoint) => endpoint.id === id);
        if (endpoint) {
          endpoint.status = value;
          setData(newData);
        }
      }),
    );
  };

  const onDeleteNamespace = (namespace: string) => {
    setNamespaceToDelete(namespace);
    deleteNamespace(() =>
      agent.upstreamEndpoint.deleteByNamespace(namespace).then(() => {
        refetch();
        setNamespaceToDelete(null);
      }),
    );
  };

  const onSwagger = (namespace:string)=>{
    window.open(`apis/swagger/${namespace}`, '_blank');
  }

  LocalStorageSettingsStore;
  return {
    data,
    t,
    loading,
    rangeValues,
    filterChange,
    filteredData,
    rangeChange,
    handleNavigate,
    onChangeStauts,
    onDeleteNamespace,
    isDeleting,
    namespaceToDelete,
    setNamespaceToDelete,
    onSwagger
  };
}

export default useOverviewHook;
