import { Application } from '@plasma/ui.application.app-provider';

export const applications: Application[] = [
  {
    icon: 'data:image/svg+xml;base64,PCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuNiwgU1ZHIEV4cG9ydCBQbHVnLUluICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjQ5NS4ycHgiDQoJIGhlaWdodD0iNDk1LjJweCIgdmlld0JveD0iMCAwIDQ5NS4yIDQ5NS4yIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0OTUuMiA0OTUuMjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6I0UxMDgxNDt9DQoJLnN0MXtmaWxsOiMwNzc1QkM7fQ0KCS5zdDJ7ZmlsbDojRkZGRkZGO30NCjwvc3R5bGU+DQo8ZGVmcz4NCjwvZGVmcz4NCjxwb2x5Z29uIGNsYXNzPSJzdDAiIHBvaW50cz0iMjQ2LjksMCAyNDYuOSwxNjUuMSAzMzAuMSwxNjUuMSAzMzAuMSwyNDYuOSA0OTUuMiwyNDYuOSA0OTUuMiwwICIvPg0KPHBvbHlnb24gY2xhc3M9InN0MSIgcG9pbnRzPSIzMzAuMSw0OTUuMiAzMzAuMSwzMzAuMSAxNjUuMSwzMzAuMSAxNjUuMSwxNjUuMSAwLDE2NS4xIDAsNDk1LjIgIi8+DQo8cmVjdCB4PSIyMDYiIHk9IjIwNiIgY2xhc3M9InN0MiIgd2lkdGg9IjgzLjIiIGhlaWdodD0iODMuMiIvPg0KPC9zdmc+DQo=',
    iconDark:
      'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExIDExSDFWMzFIMjFWMjFIMTFWMTFaIiBmaWxsPSIjMDc3NUJDIi8+CjxwYXRoIGQ9Ik0xNiAxMVYxSDMxVjE2SDIxVjExSDE2WiIgZmlsbD0iI0UxMDgxNCIvPgo8cmVjdCB4PSIxMyIgeT0iMTMiIHdpZHRoPSI2IiBoZWlnaHQ9IjYiIGZpbGw9IiMxQTFBMTgiLz4KPC9zdmc+Cg==',
    favorite: false,
    id: '${REPO_NAME}',
    name: 'API Gateway',
    aboutUrl: '/home',
    url: 'http://localhost:3000',
    version: '2.0.1',
  },
];
